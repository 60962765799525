import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.scss';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import 'react-toastify/dist/ReactToastify.css';
import { connect } from 'react-redux';
import { PublicRoute } from './components/Routes';
import { BasicSpinner } from './components/Loader';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { CaptchaPublicRoute, PrivateRoute2 } from './components/Routes/CaptchaRoute';

// import Tawkjs from './utils/tawk';

// Containers
const NewLayout = lazy(() => import('./containers/NewLayout'));

// Pages
const Login = lazy(() => import('./views/Login'));
const LoginOTP = lazy(() => import('./views/Login/otp'));
const Auth = lazy(() => import('./views/Pages/Auth/Auth'));
const Page404 = lazy(() => import('./views/Pages/Page404'));
const Page500 = lazy(() => import('./views/Pages/Page500'));
const LandingPage = lazy(() => import('./views/Pages/LandingPage/LandingPage'));

function App() {
  return (
    <BrowserRouter>
      <Suspense
        fallback={
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <BasicSpinner />
          </div>
        }
      >
        <Switch>
          <PublicRoute path='/auth' name='Login Page' component={Auth} />
          <CaptchaPublicRoute path='/login' component={Login} withReCaptcha />
          <PublicRoute path='/login-otp' name='Login OTP' component={LoginOTP} />
          <PrivateRoute2 exact path='/' name='Landing Page' component={LandingPage} />
          <Route exact path='/404' name='Page 404' render={(props) => <Page404 {...props} />} />
          <Route exact path='/500' name='Page 500' render={(props) => <Page500 {...props} />} />
          <Route path='/' name='Home' render={(props) => <NewLayout {...props} />} />
          {/* <PrivateRoute patch='/create-order' exact={true} name='create-order' component={CreateOrder} permission={PERMISSION.TRANSACTION.CREATE_TRANSACTION} version='qoinhub' /> */}
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}

const mapStateToProps = (state) => ({
  profile: state.profile,
});

export default connect(mapStateToProps, null)(App);
