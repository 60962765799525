import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { getCookie } from '../../../utils';

export const PrivateRoute2 = ({ component: Component, ...rest }) => {
  const isAuthenticated = !!getCookie('token-web-payout');

  return (
    <Route {...rest} render={(props) => (isAuthenticated ? <Component {...props} /> : <Redirect to='/login' />)} />
  );
};

export const CaptchaPublicRoute = ({ component: Component, withReCaptcha, ...rest }) => {
  const isAuthenticated = !!getCookie('token-web-payout');
  if (isAuthenticated) return <Redirect to={'/dashboard'} />;
  const ComponentWrapper = (props) =>
    withReCaptcha ? (
      <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_SITE_KEY}>
        <Component {...props} />
      </GoogleReCaptchaProvider>
    ) : (
      <Redirect to={'/dashboard'} />
    );

  return <Route {...rest} render={ComponentWrapper} />;
};
